import { i18n } from "@bleu/ui";
import { ParticipantProvider } from "@contexts/ParticipantContext";
import { ProgramProvider } from "@contexts/ProgramContext";
import ChallengePage from "@pages/programs/challenges/ChallengePage";
import ErrorBoundary from "@pages/programs/ErrorBoundary";
import EditEmailSettings from "@pages/programs/my-account/EditEmailSettings";
import { MyAccountPage } from "@pages/programs/my-account/MyAccountPage";
import { SignInPage } from "@pages/programs/signin/SignInPage";
import React, { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import MerchantOffers from "../pages/programs/my-offers/MerchantOffers";
import Offers from "../pages/programs/my-offers/Offers";

const router = createBrowserRouter([
  {
    path: "/signin",
    element: <SignInPage />,
    action: SignInPage.action,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/my-account",
    element: <MyAccountPage />,
    action: EditEmailSettings.action,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/challenge/:challenge_id",
    element: <ChallengePage />,
    loader: ChallengePage.loader,
    action: ChallengePage.action,
  },
  {
    path: "/my-offers",
    element: <Offers />,
    loader: Offers.loader,
  },
  {
    path: "/my-offers/:merchant_slug",
    element: <MerchantOffers />,
    loader: MerchantOffers.loader,
  },
]);

const App = ({ participant, program }) => {
  useEffect(() => {
    i18n.default.changeLanguage("en");
  }, []);
  return (
    <React.StrictMode>
      {/* @ts-ignore */}
      <I18nextProvider i18n={i18n.default}>
        <ProgramProvider program={program}>
          <ParticipantProvider participant={participant}>
            <RouterProvider router={router} />
          </ParticipantProvider>
        </ProgramProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
};

export default App;
