import Card from "@components/ui/Card";
import React from "react";

import { CloseButton } from "./components/CloseButton";

export function Submitted({ successMessage }: { successMessage?: string }) {
  return (
    <Card.Root className="w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border z-[1450] !rounded-perk-cards">
      <Card.Header>
        <div className="flex w-full justify-end">
          <CloseButton />
        </div>
        <Card.Title className="pl-0 text-xl text-center">
          {successMessage || "Challenge Submitted successfully!"}
        </Card.Title>
        <Card.Description className="text-center text-base">
          Earn more points by completing more challenges.
        </Card.Description>
      </Card.Header>
      <Card.Footer className="flex justify-center mb-2">
        <button
          className="custom_btn zoom_btn"
          type="button"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Earn More
        </button>
      </Card.Footer>
    </Card.Root>
  );
}
