import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Form,
  SubmitButton,
} from "@bleu/ui";
import { buildForm } from "@utils/buildForm";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useActionData } from "react-router-dom";

export function AddPointsDialog({
  handleSubmit,
}: {
  handleSubmit: (formState) => Promise<void>;
}) {
  const form = useForm();
  const actionData = useActionData() as {
    success: boolean;
    intent: string;
    data: {
      [key: string]: string;
    };
  };

  useEffect(() => {
    if (actionData && !actionData?.data?.error) {
      window.location.reload();
    }
  }, [actionData]);

  const FIELDS = [
    {
      name: "points",
      label: "Points",
      description:
        "Number of points to award this user. Must be a positive integer.",
      type: "input",
      mode: "number",
      required: true,
    },
    {
      name: "action_title",
      label: "Action Title",
      description:
        "This is title that will be shown in the user's activity feed.",
      type: "input",
      required: true,
    },
  ];

  return (
    <Dialog>
      <DialogTrigger asChild>
        <SubmitButton
          type="button"
          className="text-sm"
          variant="outline"
          size="sm"
        >
          Add points
        </SubmitButton>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[550px]">
        <DialogTitle>Add Points to User</DialogTitle>
        <Form {...form} onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="space-y-4">
            {buildForm(FIELDS, form)}
            <div className="flex gap-x-4 justify-end">
              <DialogClose>
                <Button type="button" variant="outline">
                  Cancel
                </Button>
              </DialogClose>
              <SubmitButton>Add</SubmitButton>
            </div>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
