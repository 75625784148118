import { cn, Form, Toaster } from "@bleu/ui";
import Card from "@components/ui/Card";
import { buildForm } from "@utils/buildForm";
import React from "react";
import { useForm } from "react-hook-form";

import { CardContent } from "../components/CardContent";
import { CardHeaderContent } from "../components/CardHeader";
import { FooterButton } from "../components/FooterButton";
import { ChallengeData } from "../components/types";

export function CollectCard({
  data,
  completedCondition,
  handleSubmit,
}: {
  completedCondition: boolean;
  data: ChallengeData;
  handleSubmit: ({ form }: { form: unknown }) => Promise<void>;
}) {
  const defaultValues = data.participant_answers.reduce((acc, answer) => {
    const key = `question_${answer.question_id}`;

    if (!acc[key]) {
      acc[key] = answer.question_option_id
        ? answer.question_option_id.toString()
        : answer.answer;
    } else if (Array.isArray(acc[key])) {
      acc[key].push(answer.question_option_id.toString());
    } else {
      acc[key] = [acc[key], answer.question_option_id.toString()];
    }

    return acc;
  }, {});

  const form = useForm({
    defaultValues,
  });

  completedCondition =
    data.parameters === "quiz"
      ? data?.completed_by_current_participant_on !== null
      : data?.completed_by_current_participant_on !== null &&
        data.participant_answers.length > 0;

  return (
    <Card.Root className="w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border z-[1500] !rounded-perk-cards">
      <Card.Header className={cn(completedCondition && "pb-0")}>
        <CardHeaderContent
          data={data}
          completedCondition={completedCondition}
        />
      </Card.Header>
      <CardContent data={data} completedCondition={completedCondition} />
      <Form {...form} className="space-y-8 mx-10">
        <div className="flex flex-wrap gap-3 p-2">
          {/* @ts-ignore */}
          {buildForm(buildFormFields(data.questions), form)}
        </div>
      </Form>
      <Card.Footer className="flex justify-center mb-2">
        <FooterButton
          data={data}
          completedCondition={completedCondition}
          handleSubmit={async () => await handleSubmit({ form })}
        />
      </Card.Footer>
    </Card.Root>
  );
}

export function buildFormFields(questions) {
  return questions.map((question) => {
    const baseField = {
      name: `question_${question.id}`,
      label: question.title,
      description: question.additional_details || undefined,
      type: mapAnswerTypeToFieldType(question.answer_type),
      required: question.is_required,
    };

    const hasOptions = question.options && question.options.length > 0;

    if (
      question.answer_type === "image_radio_button" ||
      question.answer_type === "image_check_box"
    ) {
      return {
        ...baseField,
        options: question.options.map((option) => ({
          value: option.id.toString(),
          label: option.details,
          imageUrl: option.image.public_url,
          alt: option.image.alt_text,
        })),
      };
    }
    if (hasOptions) {
      return {
        ...baseField,
        options: question.options.map((option) => ({
          value: option.id.toString(),
          label: option.details,
        })),
      };
    }
    if (
      question.answer_type === "number" ||
      question.answer_type === "decimal"
    ) {
      return {
        ...baseField,
        mode: "number",
      };
    }
    if (question.answer_type === "time") {
      return {
        ...baseField,
        mode: "time",
      };
    } else {
      return {
        ...baseField,
      };
    }
  });
}

export function mapAnswerTypeToFieldType(answerType) {
  switch (answerType) {
    case "text_area":
      return "textarea";
    case "dropdown":
      return "select";
    case "date":
      return "date";
    case "date_time":
      return "datetime";
    case "radio_button":
    case "boolean":
      return "radio_item";
    case "wysiwyg":
      return "wysiwyg";
    case "image_radio_button":
      return "image_radio";
    case "check_box":
      return "multi_select_checkbox";
    case "image_check_box":
      return "image_checkbox";
    default:
      return "input";
  }
}
