import { ClickToCopy, cn, Input, Toaster } from "@bleu/ui";
import Card from "@components/ui/Card";
import React from "react";

import { CardContent } from "./components/CardContent";
import { CardHeaderContent } from "./components/CardHeader";
import { ChallengeData } from "./components/types";

export function Referral({
  data,
  handleSubmit,
}: {
  data: ChallengeData;
  handleSubmit: () => void;
}) {
  const completedCondition = data?.completed_by_current_participant_on !== null;
  return (
    <Card.Root className="w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border !rounded-perk-cards">
      <Card.Header className={cn(completedCondition && "pb-0")}>
        <CardHeaderContent
          data={data}
          completedCondition={completedCondition}
        />
      </Card.Header>
      <CardContent data={data} completedCondition={completedCondition} />
      <Card.Footer className="flex justify-center mb-2 mx-4 flex-col">
        <p className="text-center text-balance">
          Copy your recruting link bellow and send it via text, email whatsapp,
          whatever!
        </p>
        <div className="flex flex-col gap-y-2 w-full px-20 mb-2">
          <div className="flex items-center gap-x-2">
            <Input
              placeholder="API Key Value"
              value={data.referral_url}
              disabled
            />
            <ClickToCopy text={data.referral_url} className="border h-10 px-2">
              <button className="ml-2 text-sm font-semibold text-primary focus:outline-none">
                Copy
              </button>
            </ClickToCopy>
          </div>
        </div>
      </Card.Footer>
    </Card.Root>
  );
}
