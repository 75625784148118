import { adminV2Participants, adminV2Programs } from "@api/index";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Select,
  SubmitButton,
  TaggablePopover,
} from "@bleu/ui";
import Link from "@components/Link";
import Card from "@components/ui/Card";
import { SimpleKpiCard } from "@components/ui/SimpleKpiCard";
import { useFormActionToast } from "@hooks/useFormActionToast";
import { useSendActionData } from "@hooks/useSendActionData";
import {
  AddressElement,
  InfoList,
} from "@pages/admin/(components)/DetailsCardElements";
import { cn } from "@utils/mergeClassNames";
import copy from "copy-to-clipboard";
import React from "react";
import {
  json,
  Outlet,
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";

import { AddPointsDialog } from "../(components)/AddPointsDialog";

const paths = ({ end_user_id, program_id }) => [
  {
    name: "Actions",
    path: `/admin/v2/programs/${program_id}/users/${end_user_id}`,
  },
  {
    name: "Rewards",
    path: `/admin/v2/programs/${program_id}/users/${end_user_id}/rewards`,
  },
  {
    name: "Challenges",
    path: `/admin/v2/programs/${program_id}/users/${end_user_id}/challenges`,
  },
  {
    name: "Attributes",
    path: `/admin/v2/programs/${program_id}/users/${end_user_id}/attributes`,
  },
  {
    name: "Participant Answers",
    path: `/admin/v2/programs/${program_id}/users/${end_user_id}/participant_answers`,
  },
  {
    name: "Blockchain Transactions",
    path: `/admin/v2/programs/${program_id}/users/${end_user_id}/blockchain_transactions`,
  },
];

const info = (data) => [
  {
    value: data.email,
    label: "Email",
  },
  {
    value:
      status.find((currentStatus) => currentStatus.value === data.status)
        .label ?? data.status,
    label: "Status",
  },
  {
    element: <AddressElement address={data.aptos_wallet_address} />,
    label: "Aptos Wallet",
    value: data.aptos_wallet_address,
  },
];

const status = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Opted Out",
    value: "opted_out",
  },
  {
    label: "Blocked",
    value: "blocked",
  },
];

const intents = {
  toggle_tag: adminV2Participants.toggleTag,
  get_magic_link: adminV2Participants.magicLink,
  reset_activities: adminV2Participants.resetActivities,
  toggle_participant_status: adminV2Participants.toggleParticipantStatus,
  create_action: adminV2Participants.createAction,
};

const intentToastTitle = {
  toggle_tag: "Tag updated",
  get_magic_link: "Magic link copied to clipboard",
  reset_activities: "Activities reset",
  toggle_participant_status: "Status updated",
  create_action: "Points Added",
};

const loader = async ({ params }) => {
  const { end_user_id, program_id } = params;
  const data = await adminV2Participants.show({ id: end_user_id });
  const tags = await adminV2Programs.tags({ id: program_id });

  return json({
    data,
    tags: tags.tags,
  });
};

const action = async ({ request }) => {
  const body = await request.json();

  if (!intents[body.intent]) {
    return json({
      success: false,
      message: "Invalid intent",
    });
  }

  let data;

  if (
    ["toggle_participant_status", "toggle_tag", "create_action"].includes(
      body.intent,
    )
  ) {
    data = await intents[body.intent]({ data: { ...body.data } });
  } else {
    data = await intents[body.intent]({ ...body.data });
  }

  if (body.intent === "get_magic_link") {
    copy(data.magic_link);
  } else if (body.intent === "reset_activities") {
    window.location.reload();
  }

  return json({
    data,
    intent: body.intent,
    success: true,
  });
};

function ParticipantDetailsPage() {
  const { pathname } = useLocation();
  const { program_id, end_user_id } = useParams();
  // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
  const { data, tags } = useLoaderData();
  const actionData = useActionData();
  const { submitWithIntent } = useSendActionData();

  useFormActionToast(actionData, intentToastTitle);

  const tagSubmission = async ({ tag }) => {
    await submitWithIntent("toggle_tag", { tag, id: end_user_id });
  };

  const getMagicLink = async () => {
    await submitWithIntent("get_magic_link", { id: end_user_id });
  };

  const handleResetActivities = async () => {
    await submitWithIntent("reset_activities", { id: end_user_id });
  };

  const handleStatusChange = async (status) => {
    await submitWithIntent("toggle_participant_status", {
      status,
      id: end_user_id,
    });
  };

  const handleAddPoints = async (formState) => {
    await submitWithIntent("create_action", {
      ...formState,
      id: end_user_id,
    });
  };

  return (
    <div className="container mx-0 pt-5 pl-8">
      <div className="grid grid-cols-10 gap-10">
        <div className="col-span-3">
          <Card.Root className="max-w-none border-2 shadow-sm bg-background">
            <Card.Header className="mb-0 pb-0">
              <div className="border-b-1 flex w-full flex-col items-center justify-center">
                <img
                  src={data.avatar.url}
                  alt=""
                  className="w-h-28 h-28 rounded-full object-cover"
                />
                <h1 className="mt-1 text-xl font-semibold text-foreground">
                  {data.name}
                </h1>
              </div>
              <Card.Description className="flex flex-col gap-1 py-2 text-sm">
                <Button
                  className="text-sm"
                  size="sm"
                  variant="outline"
                  onClick={getMagicLink}
                >
                  Copy magic link
                </Button>
                <AddPointsDialog handleSubmit={handleAddPoints} />
                <ResetActivities handleSubmit={handleResetActivities} />
              </Card.Description>
            </Card.Header>
            <div className="border-b dard:border-b-4" />
            <Card.Content className="mx-4 items-center">
              <div className="mt-2 px-6">
                <InfoList data={info(data)} />
              </div>
            </Card.Content>
            <Card.Footer className="mt-4 flex flex-row justify-center gap-1">
              <TaggablePopover
                tags={tags}
                selectedTags={data.tag_list}
                onSelect={tagSubmission}
              />
              <Select.SelectRoot
                onValueChange={handleStatusChange}
                value={data.status}
              >
                <Select.SelectTrigger className="h-8 w-[120px] rounded-sm border dark:border-2 shadow-sm">
                  <Select.SelectValue placeholder="Status" />
                </Select.SelectTrigger>
                <Select.SelectContent>
                  <Select.SelectGroup>
                    {status.map((item, idx) => (
                      <Select.SelectItem
                        key={idx}
                        value={item.value}
                        className="rounded-md"
                      >
                        {item.label}
                      </Select.SelectItem>
                    ))}
                  </Select.SelectGroup>
                </Select.SelectContent>
              </Select.SelectRoot>
            </Card.Footer>
          </Card.Root>
        </div>
        <div className="col-span-7">
          <div className="flex gap-5">
            <SimpleKpiCard title="Login Count">
              {data.sign_in_count}
            </SimpleKpiCard>
            <SimpleKpiCard title="Points">{data.unused_points}</SimpleKpiCard>
            <SimpleKpiCard title="Challenges Completed">
              {data.completed_challenges}
            </SimpleKpiCard>
            <SimpleKpiCard title="Rewards Earned">
              {data.rewards_earned}
            </SimpleKpiCard>
            <SimpleKpiCard title="Clicks">{data.clicks}</SimpleKpiCard>
          </div>
          <div className="mt-2 flex gap-3 flex-wrap">
            {paths({ program_id, end_user_id }).map((item, idx) => (
              <Link
                key={idx}
                to={item.path}
                className={cn(
                  "text-muted-foreground px-2 py-1 text-sm font-medium",
                  {
                    "bg-accent border-2-primary rounded-md text-foreground":
                      pathname === item.path,
                  },
                )}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="mr-10 mt-5">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

function ResetActivities({ handleSubmit }) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <SubmitButton type="button" className="text-sm" size="sm">
          Reset Activities
        </SubmitButton>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This will reset all activities for this participant. This can not be
            undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <SubmitButton type="button" onClick={handleSubmit}>
            Reset
          </SubmitButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

ParticipantDetailsPage.loader = loader;
ParticipantDetailsPage.action = action;
export default ParticipantDetailsPage;
