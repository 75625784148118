import { SWRDataTable, Toaster } from "@bleu/ui";
import Card from "@components/ui/Card";
import { SimpleKpiCard } from "@components/ui/SimpleKpiCard";
import { useParticipant } from "@contexts/ParticipantContext";
import { useProgram } from "@contexts/ProgramContext";
import {
  CalendarIcon,
  EnvelopeClosedIcon,
  PersonIcon,
  SewingPinIcon,
} from "@radix-ui/react-icons";
import { formatDate } from "@utils/formatDate";
import toTitleCase from "@utils/toTitleCase";
import React from "react";
import { Navigate } from "react-router-dom";

import EditEmailSettings from "./EditEmailSettings";

const AVATAR_FALLBACK_URL =
  "https://media.istockphoto.com/id/1300845620/pt/vetorial/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=7TO9d1_F-zi74bCZGEUzpa-nXT1JbcVglYMk_4MSwdg=";

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="size-4"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
    />
  </svg>
);

const MailingAddress = ({ profile_attributes }) => {
  const { address_1, address_2, city, state, postal } = profile_attributes;

  const hasAddress = address_1 || address_2 || city || state || postal;

  if (!hasAddress) return null;

  return (
    <>
      <div className="text-sm font-semibold text-muted-foreground">
        Mailing Address
      </div>
      <div className="flex items-center space-x-2">
        <SewingPinIcon className="size-4 text-perk-primary" />
        <div className="">
          <p className="mb-0">{address_1}</p>
          {address_2 && <p className="mb-0">{address_2}</p>}
          <p className="mb-0 custom_h2">
            {city && ` ${city}`}
            {state && ` ${state}`}
            {postal && (city || state) ? " - " : ""}
            {postal}
          </p>
        </div>
      </div>
    </>
  );
};

const ProfileInfomCard = ({ participant }) => (
  <Card.Root className="w-full max-w-none bg-white border pb-6 !rounded-perk-cards">
    <Card.Header>
      <Card.Title className="pl-0 text-xl text-start text-perk-primary">
        Profile Information
      </Card.Title>
    </Card.Header>

    <Card.Content className="mx-4 flex flex-col md:flex-row gap-x-10 gap-y-4">
      <div className="flex justify-center">
        <img
          src={participant?.avatar.url || AVATAR_FALLBACK_URL}
          className="size-28 object-fill rounded-full border"
          alt="Profile"
        />
      </div>
      <div className="flex-1 grid gap-4 md:grid-cols-2">
        <div className="space-y-1">
          <div className="text-sm font-semibold text-muted-foreground">
            Name
          </div>
          <div className="flex items-center space-x-2">
            <PersonIcon className="size-4 text-perk-primary" />
            <span className="">{participant?.name}</span>
          </div>
        </div>
        <div className="space-y-1">
          <div className="text-sm font-semibold text-muted-foreground">
            Email
          </div>
          <div className="flex items-center space-x-2">
            <EnvelopeClosedIcon className="size-4 text-perk-primary" />
            <span className="">{participant?.email}</span>
          </div>
        </div>

        {participant?.profile_attributes?.phone && (
          <div className="space-y-1">
            <div className="text-sm font-semibold text-muted-foreground">
              Phone
            </div>
            <div className="flex items-center space-x-2">
              <div className="text-perk-primary">
                <PhoneIcon />
              </div>
              <span className="">{participant?.profile_attributes?.phone}</span>
            </div>
          </div>
        )}
        <div className="space-y-1">
          <div className="text-sm font-semibold text-muted-foreground">
            Member Since
          </div>
          <div className="flex items-center space-x-2">
            <CalendarIcon className="size-4 text-perk-primary" />
            <span className="">{formatDate(participant?.created_at)}</span>
          </div>
        </div>
        <div className="space-y-1 md:col-span-2">
          <MailingAddress
            profile_attributes={participant?.profile_attributes}
          />
        </div>
        {participant?.profile_attributes?.affiliation && (
          <div className="space-y-1 md:col-span-2">
            <div className="text-sm font-semibold text-muted-foreground">
              Affinity and Communications
            </div>
            <div className="flex items-center space-x-2">
              <span className="">
                {participant?.profile_attributes?.affiliation
                  .split(",")
                  .map((affiliation) => toTitleCase(affiliation.trim()))
                  .join(", ")}
              </span>
            </div>
          </div>
        )}
      </div>
    </Card.Content>
  </Card.Root>
);

function EmailOptions({ program, participant }) {
  const program_email_settings = program.email_settings;
  const participant_email_setting = program_email_settings.find(
    (setting) => setting.id === participant.email_setting_id,
  );
  return (
    <Card.Root className="w-full max-w-none bg-white border pb-6 !rounded-perk-cards">
      <Card.Header className="pb-2 flex justify-between flex-row">
        <Card.Title className="pl-0 text-xl text-start text-perk-primary">
          Email Settings
        </Card.Title>
        <EditEmailSettings program={program} participant={participant} />
      </Card.Header>

      <Card.Content className="flex justify-center flex-col mt-2 max-w-2xl px-4 gap-y-4">
        <>
          {participant_email_setting && (
            <div>{participant_email_setting.description} </div>
          )}
        </>
        <span className="text-xs">
          You can opt in to receive emails from {program.name} for news about
          product and services. Stay informed about the latest challenges and
          rewards by keeping your email preferences up to date.
        </span>
      </Card.Content>
    </Card.Root>
  );
}

const PariticpantActions = () => (
  <Card.Root className="w-full max-w-none bg-white border pb-6 !rounded-perk-cards">
    <Card.Header>
      <Card.Title className="pl-0 text-xl text-start text-perk-primary">
        Account Activity
      </Card.Title>
    </Card.Header>

    <Card.Content className="px-6 flex justify-center">
      <div className="mt-2 max-w-[250px] sm:max-w-full">
        <SWRDataTable searchKey="title" fetchPath={`/participant-actions`} />
      </div>
    </Card.Content>
  </Card.Root>
);

function MyAccountPage() {
  const program = useProgram();
  const participant = useParticipant();

  if (!participant) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      <Toaster position="top-right" />
      <div className="my-10 md:mt-20 pt-5 md:pt-10 min-h-[calc(100vh-80px-40px-95px)] flex flex-col items-center md:px-20">
        <div className="space-y-6 p-3">
          <div className="flex flex-col md:flex-row gap-x-4">
            <SimpleKpiCard title="Challenges Completed">
              {participant?.completed_challenges}
            </SimpleKpiCard>
            <SimpleKpiCard title="Current Balance">
              {participant?.unused_points} {program.term_points_as}
            </SimpleKpiCard>
            <SimpleKpiCard title="Lifetime Earnings">
              {participant?.points} {program.term_points_as}
            </SimpleKpiCard>
          </div>

          <div className="flex justify-end items-center pb-2 m-0">
            <p className="text-xs text-perk-primary-content m-0 text-end">
              *It may take up to 24 hours to assign points to your activity as
              we verify your posts and actions.
              <br />
              **There may be limits on the number of likes, comments, retweets,
              etc. that you can earn. See FAQs for more details.
            </p>
          </div>

          <ProfileInfomCard participant={participant} />

          <EmailOptions program={program} participant={participant} />
          <PariticpantActions />
        </div>
      </div>
    </>
  );
}

export { MyAccountPage };
