import {
  Button,
  Checkbox,
  cn,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SubmitButton,
} from "@bleu/ui";
import Card from "@components/ui/Card";
import { Turnstile } from "@marsidev/react-turnstile";
import { ArrowLeftIcon, Cross1Icon } from "@radix-ui/react-icons";
import React from "react";

export function NewParticipantForm({
  program,
  form,
  handleSubmit,
  setIsNewParticipant,
}) {
  const [turnStileSolved, setTurnStileSolved] = React.useState(false);
  const turnstileRef = React.useRef(null);

  if (program?.override_enrollment && program?.program_custom_signin_url) {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get("redirectTo");
    const email = form.watch("email");

    let signInUrl = `${program.program_custom_signin_url}?email=${email}`;
    if (redirectTo) {
      signInUrl += `&redirect_to=${redirectTo}`;
    }

    window.location.href = signInUrl;
    return;
  }
  const agreeTerms = form.watch("agree_terms");

  const siteKey =
    process.env.NODE_ENV === "production"
      ? "0x4AAAAAAAS_lW6bn8FCP_nm"
      : "1x00000000000000000000AA";

  const onSubmit = async () => {
    form.setValue("cf-turnstile-response", turnstileRef.current?.getResponse());
    await handleSubmit();
  };

  return (
    <Card.Root className="w-full md:w-2/3 xl:w-1/2 max-w-none bg-white border pb-6 !rounded-perk-cards">
      <Card.Header>
        <div className="flex w-full justify-between">
          <Button
            className="bg-transparent shadow-none w-fit p-2 outline-perk-primary"
            onClick={() => {
              setIsNewParticipant(false);
            }}
          >
            <ArrowLeftIcon className="size-3" />
          </Button>
          <Button
            className="bg-transparent shadow-none w-fit p-2 outline-perk-primary"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <Cross1Icon className="size-3" />
          </Button>
        </div>
        <Card.Title className="pl-0 text-xl text-center">
          Join {program?.name} and start earning rewards!
        </Card.Title>
      </Card.Header>

      <Card.Content className="mx-4">
        <Form {...form}>
          <div className="grid gap-2">
            <div className="flex flex-wrap gap-4">
              <ParticipantFormFields form={form} program={program} />
            </div>
            <SubmitButton
              className="bg-perk-primary text-perk-primary-content hover:bg-perk-primary/90 rounded-perk-button"
              type="button"
              onClick={onSubmit}
              disabled={!agreeTerms}
            >
              Sign up and earn&nbsp;
              {program?.signup_challenge?.points && (
                <span>+{program?.signup_challenge?.points}</span>
              )}
              &nbsp;{program.term_points_as}
            </SubmitButton>
            <div className={cn("w-full flex justify-center")}>
              <Turnstile
                onSuccess={() => setTurnStileSolved(true)}
                onError={() => setTurnStileSolved(false)}
                siteKey={siteKey}
                ref={turnstileRef}
                options={{
                  action: "login",
                  theme: "light",
                }}
              />
            </div>
          </div>
        </Form>
      </Card.Content>
    </Card.Root>
  );
}

function ParticipantFormFields({ form, program }) {
  const fields = [
    {
      name: "first_name",
      type: "input",
      label: "First Name *",
      required: true,
    },
    {
      name: "last_name",
      type: "input",
      label: "Last Name *",
      required: true,
    },
    {
      name: "postal",
      type: "input",
      label: "Zip Code",
    },
  ];

  return (
    <>
      {fields.map((field) => (
        <FormField
          key={field.name}
          control={form.control}
          name={field.name}
          render={({ field: formField }) => (
            <FormItem className="w-full">
              <FormControl>
                {field.type === "input" && (
                  <>
                    <FormLabel>{field.label}</FormLabel>
                    <Input
                      className="placeholder:text-gray-400 rounded-perk-input"
                      placeholder={field.label}
                      required={field.required}
                      {...formField}
                    />
                  </>
                )}
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
      ))}
      <FormField
        control={form.control}
        name="agree_terms"
        render={({ field: formField }) => (
          <FormItem className="w-full">
            <div className="flex gap-x-2">
              <FormControl>
                <Checkbox
                  className="!rounded-perk-input"
                  checked={formField.value}
                  onCheckedChange={formField.onChange}
                />
              </FormControl>
              <FormLabel>
                <TermsAndRules pages={program.published_program_pages} />
              </FormLabel>
            </div>
            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
    </>
  );
}

function TermsAndRules({ pages }) {
  const linkTexts = {
    Privacy: "privacy policy",
    Rules: "rules",
    Terms: "terms",
  };

  const links = Object.entries(linkTexts).reduce((acc, [key, text]) => {
    const page = pages.find((page) => page.title.includes(key));
    if (page) {
      acc.push(
        <a
          key={key}
          href={`/${page.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>,
      );
    }
    return acc;
  }, []);

  return (
    <div>
      {links.length > 0 ? (
        <p>
          By signing up, you agree to the&nbsp;
          {links.map((link, index) => (
            <span key={index}>
              {index > 0 && (index === links.length - 1 ? " and " : ", ")}
              {link}
            </span>
          ))}
          &nbsp;of this program.
        </p>
      ) : (
        <p>
          By signing up, you agree to the terms and conditions of this program.
        </p>
      )}
    </div>
  );
}
