import { Toaster } from "@bleu/ui";
import React from "react";

export function EndUserLayout({ children }: React.PropsWithChildren) {
  return (
    <div className="my-10 md:mt-20 pt-5 md:pt-10 min-h-[calc(100vh-80px-40px-95px)] flex flex-col items-center">
      <div className="z-[1500]">
        <Toaster position="top-right" />
      </div>
      <div className="flex px-3 w-full">{children}</div>
    </div>
  );
}
