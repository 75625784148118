import { toast, Toaster } from "@bleu/ui";
import { useProgram } from "@contexts/ProgramContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { client } from "@utils/api-client";
import { serialize } from "object-to-formdata";
import React from "react";
import { useForm } from "react-hook-form";
import { json, useActionData, useSubmit } from "react-router-dom";
import { z } from "zod";

import { EndUserLayout } from "../Layout";
import { EnterForm } from "./EnterForm";
import { MagicLinkSent } from "./MagicLinkSent";
import { NewParticipantForm } from "./NewParticipantForm";

interface ActionData {
  ok: boolean;
  participant: {
    first_name: string;
  };
}

const emailSchema = z.object({
  email: z.string().email("Invalid email"),
});

const action = async ({ request }) => {
  const formData = await request.formData();
  const response = await client("/signin-verify", { body: formData });

  return json(response);
};

function SignInPage() {
  const program = useProgram();
  const [isNewParticipant, setIsNewParticipant] = React.useState(false);
  const [magicLinkSent, setMagicLinkSent] = React.useState(false);
  const [participant, setParticipant] = React.useState(null);
  const form = useForm({
    resolver: zodResolver(emailSchema),
  });

  const submit = useSubmit();
  const actionData = useActionData() as ActionData;

  const handleSubmit = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const formData = serialize(form.getValues());

    params.forEach((value, key) => {
      formData.append(key, value);
    });
    submit(formData, { method: "post", encType: "multipart/form-data" });
  };

  React.useEffect(() => {
    if (actionData) {
      if (actionData.ok) {
        setParticipant(actionData.participant);
        setIsNewParticipant(false);
        setMagicLinkSent(true);
      } else {
        setIsNewParticipant(true);
        if (isNewParticipant) {
          toast({
            title: "Error!",
            description: "Required fields are missing",
            variant: "destructive",
          });
        }
      }
    }
  }, [actionData]);

  return (
    <>
      <Toaster position="top-right" />
      <EndUserLayout>
        {isNewParticipant ? (
          <div className="flex w-full justify-center">
            <NewParticipantForm
              program={program}
              form={form}
              handleSubmit={handleSubmit}
              setIsNewParticipant={setIsNewParticipant}
            />
          </div>
        ) : magicLinkSent ? (
          <div className="flex w-full justify-center">
            <MagicLinkSent participant={participant} />
          </div>
        ) : (
          <div className="flex w-full justify-center">
            <EnterForm
              program={program}
              form={form}
              handleSubmit={handleSubmit}
            />
          </div>
        )}
      </EndUserLayout>
    </>
  );
}

SignInPage.action = action;
export { SignInPage };
